import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/p/:page',
    name: 'page',
    component: () => import(/* webpackChunkName: "about" */ '../views/PageView.vue')
  },
  {
    path: '/vprasalnik/:questionaire/:id?',
    name: 'vprasalnik',
    component: () => import('../views/MasterQuestionaire.vue')
  },
  {
    path: "/rezultat/:questionaire",
    name: 'rezultat',
    component: () => import('../views/ResultPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
