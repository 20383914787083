<template>
  <Header :title='title' />
  
  <main>
    <div class="content" v-html='content'></div>
    <div class="flex-wrapper">
      <div class="consent">
        <input type="checkbox" id="consent" name="consent" v-model="answers.consent"> Razumem in se strinjam s pogoji.
        <router-link :to="{ name: 'page', params: { page: 'vprasalnik-in-pogoji-uporabe'} }">Več o vprašalniku in pogojih</router-link>
      </div>
      <div class="start-button" :class='{ disabled: ! answers.consent }'>
        <router-link 
          :to="{name: 'vprasalnik', params: { questionaire: 'triaza' }}" 
          class="umami--click--start-triaza">Začni</router-link>
      </div>
    </div>
  </main>
</template>

<script setup>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import { ref, onMounted } from 'vue'
import axios from "axios"
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { useAnswersStore } from '@/stores/answers'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

const answers = useAnswersStore()

const content = ref(null)
const title = ref(null)
const loading = ref(false)

const getContent = () => {
  const storedContent = localStorage.getItem('homeContent')
  const storedTitle = localStorage.getItem('homeTitle')

  if( storedContent === null || storedTitle === null ) {
    getContentFromApi()
  } else {
    content.value = storedContent
    title.value = storedTitle
  }
}

const getContentFromApi = function() {
  axios
      .get(process.env.VUE_APP_ROOT_API + '/wp/v2/esos-stran/?slug=vstop')
      .then( response => {
        content.value = response.data[0].content.rendered
        title.value = response.data[0].title.rendered

        localStorage.setItem('homeContent', content.value)
        localStorage.setItem('homeTitle', title.value)
        // console.log(response.data[0])
      })
}

onMounted( () => {
  getContent() 
 })

</script>

<style lang="less">

.start-button {
  
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;

  &.disabled {
    pointer-events: none;

    a {
      background-color: desaturate( lighten(#257B80, 30%), 40%);
    }
  }
  
  a {
    display: inline-block;
    background-color: #257B80;

    color: #fff;
    padding: .5em 2em;
    transition: all .3s;
    text-decoration: none;
    border-radius: 2em;
    
    &:hover {
      transform: scale(0.95, 0.95);
      background-color: lighten(#257B80, 10%);
    }
  }
}
</style>
