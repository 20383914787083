<template>

  <router-view :key="$route.fullPath"></router-view>
  
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()


</script>


<style lang="less">

:root {
  --background: #f9fbff;
  --text: #000000;
  --inverted: #ffffff;
  --main: #1C797F;
  --secondary: #DDB157;
  --main-light: #82B3B6;
  --secondary-light: #E4D8BF;
  --attention: #ff0000;
}


a {
  color: var(--text);
  text-decoration: underline;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--background);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

#app {
  display: flex;
  flex-direction: column;
}


main {
    box-sizing: border-box;
    position: absolute;
    width:100%;
    max-width: 860px;
    margin: 0 auto;
    left:0;
    right:0;
    padding-bottom: 1em;
    padding-top: 1em;
    z-index: 0;

}

h1,
h2 {
  padding: 0 1rem;
  color: var(--main); 
}
h2 {
  margin-top:0;
}

.content,
.consent {
  padding: 1rem 0;
}

.is-content-justification-right {
  text-align: right;
}

button,
.button {
  display: inline-block;
  padding: 1em 2em;
  background-color: var( --main );
  color: var(--inverted);
  border: none;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: 700;

  &:hover {
    background-color: var( --main-light );
  }

  &.alter {
    background-color: var( --secondary );
    &:hover {
      background-color: var( --secondary-light );
    }
  }
}

.wp-block-button {
  display: inline-block;
  background-color: var(--secondary);
  color: var(--inverted);
  text-transform: uppercase;
  border:none;
  border-radius: 2em;
  padding: 1em 2em;
  transition: all 300ms;
  font-size: 1rem;
  font-weight: 700;

    a {
      color: #fff;
      text-decoration: none;
    }

    &:hover,
    &:active {
      transform: scale(.9,.9);
      background-color: var(--secondary);

    }
}

</style>
