import { defineStore } from 'pinia';
import axios from 'axios';
import { useAnswersStore } from './answers';

export const useQuestionsStore = defineStore({
    'id': 'questions',
    state: () => ({
        questions: [],
        results: {},
        currentQuestionId: 0,
        currentIndex:0,
        activeAnswer: 0,
        questionaires: []
    }),
    actions: {
        getQuestionaires() {
            axios
            .get(process.env.VUE_APP_ROOT_API + '/wp/v2/vprasalnik')
            .then( response => {
                this.questionaires = response.data
            })
        },
        getQuestionaire(route) {
            axios
            .get(process.env.VUE_APP_ROOT_API + '/esos/v1/vprasalnik/' + route.params.questionaire)
            .then( response => {
                this.questions = response.data.questions
                this.results[route.params.questionaire] = response.data.results
                if( route.params.id ) {
                    this.currentQuestionId = route.params.id
                } else {
                    this.currentQuestionId = response.data.questions[0]['id']
                }
            })
        },
        next() {
            this.currentIndex = this.questions.findIndex( (e) => e.id == this.currentQuestionId )

            this.currentIndex = this.currentIndex + 1

            this.currentQuestionId = this.questions[this.currentIndex]['id']

            this.activeAnswer = 0
        },
        previous() {
            this.currentIndex = this.questions.findIndex( (e) => e.id == this.currentQuestionId )

            this.currentIndex = this.currentIndex - 1

            if( this.currentIndex >= 0 ) {
                this.currentQuestionId = this.questions[this.currentIndex]['id']
            }

        },
        setActiveAnswer() {
            const answers = useAnswersStore()

            this.question.answers.every( (e, i) => {

                if(answers.answers.find( (el) => {

                        const splid = el.id.split("-")
                        if(splid[0] == this.question.id && splid[1] == i ) {
                            return true
                        }

                    }) === undefined ) {

                    this.activeAnswer = i
                    return false
                } 
                else {
                    return true
                }
            })
            
        },
        getResultText(route) {

            const resultScale = this.results[route]
            const answers = useAnswersStore()

            const resultTier = resultScale.find( e => Number(e.breakpoint) > Number(answers.result[route]) )

            //console.log(resultTier)

            axios
            .get(process.env.VUE_APP_ROOT_API + '/wp/v2/esos-stran/' + resultTier.post_id)
            .then( response => {
                //console.log(response.data.content.rendered)
                return response.data.content.rendered
            });
        }
    }, 
    getters: {
        question: (state) => {
            //console.log(state.questions.find((id) => id == state.currentQuestionId ))
            return state.questions.find( (e) => e.id == state.currentQuestionId )
        },
        length: (state) => {
            return state.questions.length
        },
        currentQuestionComplete: (state) => {

            const answers = useAnswersStore()

            if( state.currentQuestionId == 0 ) {
                return false
            }

            const questionLength = state.questions.find( (e) => {
                return state.currentQuestionId == e.id
            }).answers.length

            const answersLength = answers.answers.filter( (e) => {
                return e.id.split("-")[0] == state.currentQuestionId
            }).length
            
            return questionLength == answersLength
        },
        questionaireComplete(state) {
            const last = state.questions.slice(-1)[0]

            return this.currentQuestionComplete && last.id == this.currentQuestionId
        }
    },
    persist: true

})